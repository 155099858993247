import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function TeamForm({ onTeamSubmit }) {
    const [teamName, setTeamName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onTeamSubmit(teamName);
        setTeamName(''); // Reset team name after submission
    };

    return (
        <div className="container mt-3">
            <form onSubmit={handleSubmit} className="row g-3">
                <div className="col-auto">
                    <label htmlFor="teamName" className="visually-hidden">Colab Name:</label>
                    <input
                        id="teamName"
                        type="text"
                        className="form-control"
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                        placeholder="Enter New Colab"
                        required
                    />
                </div>
                <div className="col-auto">
                    <button type="submit" className="btn btn-primary mb-3" style={{
                                    backgroundColor: 'green', // Blue background color
                                    color: '#ffffff', // White text color
                                    fontWeight: 'bold', // Bold font weight
                                    borderRadius: '5px', // Rounded corners
                                    border: 'none', // No border
                                    padding: '6px 12px', // Padding
                                    cursor: 'pointer', // Cursor style
                                    width: '120px', // Custom width
                                    height: '38px', // Custom height
                                }}>Create Colab</button>
                </div>
            </form>
        </div>
    );
}

export default TeamForm;
