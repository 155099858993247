
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../custom-styles.css'; // Adjust the path according to the actual file location

function TeamTasks() {
    const { link } = useParams();
    const [selectedDate, setSelectedDate] = useState(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]);
    const [tasks, setTasks] = useState([]);
    const [teamName, setTeamName] = useState('');

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await fetch(`https://backendcalendarwithashraful.cloudns.ch/api/tasks/byTeamLink/${link}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch tasks');
                }
                const data = await response.json();
                const filteredTasks = data.filter(task => new Date(task.date).toISOString().split('T')[0] === selectedDate);
                setTasks(filteredTasks);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchTasks();
    }, [link, selectedDate]);

    const findSlotForTask = (taskStartTime) => {
        const [time, period] = taskStartTime.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        hours = period === 'PM' && hours !== 12 ? hours + 12 : hours;
        hours = period === 'AM' && hours === 12 ? 0 : hours;

        // Adjust hours for 24-hour format
        hours = hours % 24;

        const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
        const amPm = hours >= 12 ? 'PM' : 'AM';
        const halfHour = minutes >= 30 ? '30' : '00';
        return `${formattedHour}:${halfHour} ${amPm}`;
    };

    const generateTimeSlots = () => {
        const slots = [];
        // Adjust start time to 6 AM and end time to 5:30 AM next day
        for (let hour = 6; hour <= 29; hour++) { // 29 represents 5 AM on the next day, to include 5:30 AM slot
            const currentHour = hour % 24; // Adjust for the 24-hour cycle
            const hour12 = currentHour % 12 === 0 ? 12 : currentHour % 12; // Convert to 12-hour format
            const amPm = currentHour >= 12 ? "PM" : "AM";

            slots.push(`${hour12}:00 ${amPm}`);
            if (hour !== 29) { // Exclude adding 5:30-6:00 slot
                slots.push(`${hour12}:30 ${amPm}`);
            }
        }

        // Manually add the last half-hour slot for 5:30 AM
        slots.push(`5:30 AM`);

        return slots;
    };


    const calculateOccupiedSlots = () => {
        let occupiedSlots = new Set();
        tasks.forEach(task => {
            const startSlot = findSlotForTask(task.time.split('-')[0]);
            const endSlot = findSlotForTask(task.time.split('-')[1]);
            let startIndex = generateTimeSlots().indexOf(startSlot);
            let endIndex = generateTimeSlots().indexOf(endSlot);

            // Adjust for tasks that end at or beyond 5:30 AM
            // Ensure endIndex is correctly set for tasks that wrap around the day
            if (endIndex < startIndex) {
                endIndex += generateTimeSlots().length; // Adjust for wrapping by adding total slots count
            }

            for (let i = startIndex + 1; i <= endIndex; i++) {
                let adjustedIndex = i % generateTimeSlots().length; // Adjust for wrapping around the slots array
                occupiedSlots.add(generateTimeSlots()[adjustedIndex]);
            }
        });
        return occupiedSlots;
    };


    const renderTableRows = () => {
        const timeSlots = generateTimeSlots();
        const occupiedSlots = calculateOccupiedSlots();

        return timeSlots.map((slot, index) => {
            const taskDetails = tasks.filter(task => findSlotForTask(task.time.split('-')[0]) === slot);
            const isSlotOccupiedWithoutStartingTask = occupiedSlots.has(slot) && taskDetails.length === 0;

            return (
                <tr key={index} className={taskDetails.length > 0 ? "task-row" : ""}>
                    <td>{slot}</td>
                    {taskDetails.length > 0 ? (
                        <>
                            <td style={{ fontSize: '16px', fontWeight: '600', color: taskDetails[0].name === "Slot Booked" ? 'orange' : 'DarkBlue', fontFamily: '' }}>
                                {taskDetails[0].name === "Slot Booked" ? "Occupied" : `🔴 ${taskDetails[0].name}`}
                            </td>
                            <td>{taskDetails[0].time}</td>
                            <td>{taskDetails[0].details}</td>
                            <td>{new Date(taskDetails[0].date).toLocaleDateString()}</td>
                            <td style={{ color:'green', fontWeight: '600', textAlign: 'center' }}>{taskDetails[0].output}</td>
                            <td style={{ fontWeight: '500', color: '', fontFamily: '', paddingRight: '25px' }}>
                                {taskDetails[0].teamString}
                            </td>
                        </>
                    ) : (
                        <td colSpan="6">
                            {isSlotOccupiedWithoutStartingTask ? (
                                <span className="text" style={{ fontSize: '', fontWeight: '200', color: 'DarkBlue', fontFamily: '' }}>↯</span>
                            ) : (
                                <span className="text" style={{ fontSize: '', fontWeight: 'Bold', color: 'green', fontFamily: '' }}>vacant</span>
                            )}
                        </td>
                    )}
                </tr>
            );
            

        });
    };

    return (
        <>
            <div className="custom-bg-color py-3 mb-4 pl-5" style={{ marginLeft: '-15px', marginRight: '-15px', display: 'flex', alignItems: 'center' }}>
                <h2 className="text-left text-white" style={{ fontSize: '38px', fontWeight: '500', color: '', fontFamily: '' }}>Calendar</h2>
                <div style={{ width: '6px' }}></div> {/* Add space */}
                <h2 className="text-left text-white" style={{ fontSize: '14px', fontWeight: '450', color: '', fontFamily: '' }}>with ashraful</h2>
            </div>

            <a className="navbar-brand" href="/">{teamName}</a>
            <div className="global-margin">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/" style={{ color: 'Green', marginBottom: '12px', fontSize: '25px', fontWeight: '800', fontFamily: '' }}>Select Date</a>
                    <form className="d-flex">
                        <input
                            type="date"
                            className="form-control"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            style={{ width: '150px', color: 'green', marginBottom: '10px', fontSize: '', fontWeight: '510', fontFamily: '' }}
                        />
                    </form>
                </div>

                <div className="container mt-5" style={{ marginBottom: '100px' }}>
                    <h2 style={{ color: 'green', marginBottom: '30px', fontSize: '35px', fontWeight: '800', fontFamily: '' }}>Time Slots</h2>
                    <div className="table-responsive">
                        <table className="table table-striped custom-striped">
                            <colgroup>
                                <col style={{ width: '10%' }} /> {/* Adjust column width as per requirement */}
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '8%' }} />
                                <col style={{ width: '14%' }} />
                                <col style={{ width: '20%' }} />

                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Time Slot</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Details</th>
                                    <th scope="col">Date</th>
                                    <th scope="col" style={{ textAlign: 'center' }}>Output</th>
                                    <th scope="col">Colab</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableRows()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TeamTasks;
