import React, { useState, useEffect } from 'react';
import TaskForm from './TaskForm';
import TeamForm from './TeamForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../custom-styles.css'; // Adjust the path according to the actual file location



function Dashboard() {
    const [tasks, setTasks] = useState([]);
    const [teams, setTeams] = useState([]); // State for storing teams
    const [selectedTeam, setSelectedTeam] = useState(''); // State for the selected team
    const [selectedDate, setSelectedDate] = useState(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]);

    useEffect(() => {
        fetchTasksForSelectedDate();
        fetchTeams();
    }, [selectedDate]);

    const fetchTasksForSelectedDate = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://backendcalendarwithashraful.cloudns.ch/api/tasks/date/${selectedDate}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token,
                },
            });

            if (!response.ok) throw new Error('Failed to fetch tasks');
            const fetchedTasks = await response.json();
            setTasks(fetchedTasks);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const fetchTeams = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://backendcalendarwithashraful.cloudns.ch/api/teams', {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setTeams(data);
            } else {
                console.error('Failed to fetch teams');
            }
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };


    const handleTaskSubmit = async (task) => {
        const token = localStorage.getItem('token');
        console.log("Token being sent:", token);

        try {
            const response = await fetch('https://backendcalendarwithashraful.cloudns.ch/api/tasks', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token, // Use 'x-auth-token' instead of 'Authorization'
                },
                body: JSON.stringify(task),
            });

            if (!response.ok) {
                const errorDetails = await response.json(); // Attempt to parse error details
                console.error('Failed to add task:', errorDetails);
                alert(`Failed to add task: ${errorDetails.msg || 'Unknown error'}`);
                return;
            }

            const addedTask = await response.json();
            console.log('Task added:', addedTask);
            alert('Task added successfully!');
            fetchTasksForSelectedDate();
        } catch (error) {
            console.error('Error adding task:', error);
            alert(`Error adding task: ${error.toString()}`);
        }
    };


    const handleTeamSubmit = async (teamName) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://backendcalendarwithashraful.cloudns.ch/api/teams', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token, // Adjust this header based on your authentication setup
                },
                body: JSON.stringify({ name: teamName }),
            });

            if (!response.ok) {
                const errorDetails = await response.json();
                console.error('Failed to add Colab:', errorDetails);
                alert(`Failed to add team: ${errorDetails.msg || 'Unknown error'}`);
                return;
            }

            const addedTeam = await response.json();
            console.log('Team added:', addedTeam);
            alert('Colab added successfully!');
            // Optionally, re-fetch teams here if you want to immediately display the new team
        } catch (error) {
            console.error('Error adding Colab:', error);
            alert(`Error adding Colab: ${error.toString()}`);
        }
    };

    const handleDeleteTask = async (taskId) => {
        // Ask for confirmation before deleting
        const isConfirmed = window.confirm("Are you sure you want to delete this task?");

        if (isConfirmed) {
            // Proceed with deletion if confirmed
            try {
                const response = await fetch(`https://backendcalendarwithashraful.cloudns.ch/api/tasks/dolot/${taskId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('token'), // Assuming you're using token-based authentication
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to delete task');
                }

                //alert('Task deleted successfully!');
                fetchTasksForSelectedDate();
                // Reload tasks or update state to reflect the deletion
                // This could be fetching tasks again or removing the task from state
            } catch (error) {
                console.error('Error deleting task:', error);
                alert('Failed to delete task');
            }
        } else {
            // Deletion was cancelled
            console.log('Task deletion cancelled.');
        }
    };



    const handleTeamSelection = (e) => {
        setSelectedTeam(e.target.value);
    };

    const copyTeamLinkToClipboard = async () => {
        const team = teams.find(t => t._id === selectedTeam);
        if (team && team.link) {
            try {
                await navigator.clipboard.writeText(`${window.location.origin}/team/${team.link}`);
                alert('Team link copied to clipboard!');
            } catch (error) {
                console.error('Could not copy text: ', error);
                alert('Failed to copy the link.');
            }
        }
    };


    const openLinkInNewTab = () => {
        const team = teams.find(t => t._id === selectedTeam);
        if (team && team.link) {
            const url = `${window.location.origin}/team/${team.link}`;
            window.open(url, '_blank').focus();
        }
    };





    const findSlotForTask = (taskStartTime) => {
        const [time, period] = taskStartTime.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        hours = period === 'PM' && hours !== 12 ? hours + 12 : hours;
        hours = period === 'AM' && hours === 12 ? 0 : hours;

        // Adjust hours for 24-hour format
        hours = hours % 24;

        // Simplify logic by directly mapping task start time to slot
        const formattedHour = hours % 12 === 0 ? 12 : hours % 12; // Convert 24-hour to 12-hour format
        const amPm = hours >= 12 ? 'PM' : 'AM';
        const halfHour = minutes >= 30 ? '30' : '00';

        return `${formattedHour}:${halfHour} ${amPm}`;
    };

    const generateTimeSlots = () => {
        const slots = [];
        // Adjust start time to 6 AM
        for (let hour = 6; hour <= 29; hour++) { // Loop until it reaches the equivalent of 5:00 AM next day
            const hourMod = hour % 24; // Wrap around after 24 hours
            const hour12 = hourMod === 0 ? 12 : hourMod <= 12 ? hourMod : hourMod - 12;
            const amPm = hourMod < 12 || hourMod === 24 ? "AM" : "PM";

            slots.push(`${hour12}:00 ${amPm}`);

            // Add half-hour slots but avoid adding for the final hour to stop at 5:30 AM
            if (hour < 29) { // This ensures we stop before adding 5:30 - 6:00 slot
                slots.push(`${hour12}:30 ${amPm}`);
            }
        }

        // Manually add the last half-hour slot for 5:30 AM
        slots.push(`5:30 AM`);

        return slots;
    };





    const calculateOccupiedSlots = (tasks, slots) => {
        let occupiedSlots = new Set();

        tasks.forEach(task => {
            let startIndex = slots.indexOf(findSlotForTask(task.time.split('-')[0]));
            let endIndex = slots.indexOf(findSlotForTask(task.time.split('-')[1]));

            // Adjust for tasks that end the next day
            if (endIndex < startIndex) {
                endIndex += slots.length; // Correctly handle wrapping by adjusting endIndex
            }

            // Mark all slots between startIndex and endIndex as occupied, including the end slot
            for (let i = startIndex + 1; i <= endIndex; i++) { // Adjusted condition to include endIndex
                let adjustedIndex = i % slots.length; // Use modulo to wrap index for next day
                occupiedSlots.add(adjustedIndex);
            }
        });

        return occupiedSlots;
    };





    const showTimeSlotStringList = () => {
        const slots = generateTimeSlots();
        const occupiedSlots = calculateOccupiedSlots(tasks, slots);

        return (
            <>
                <h3 style={{ color: 'green', marginBottom: '40px', fontSize: '35px', fontWeight: '800', fontFamily: '' }}>Time Slots</h3>
                <div className="table-responsive">
                    <table className="table table-striped custom-striped">
                        <colgroup>
                            <col style={{ width: '10%' }} /> {/* Adjust column width as per requirement */}
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '8%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '3%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">Time Slot</th>
                                <th scope="col">Name</th>
                                <th scope="col">Time</th>
                                <th scope="col">Details</th>
                                <th scope="col">Date</th>
                                <th scope="col" style={{ textAlign: 'center' }}>Output</th>
                                <th scope="col"style={{ textAlign: 'center' }}>Colab</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {slots.map((slot, index) => {
                                const tasksInSlot = tasks.filter(task => findSlotForTask(task.time.split('-')[0]) === slot);

                                if (occupiedSlots.has(index)) {
                                    // Display a message for booked slots
                                    return (
                                        <tr key={index}>
                                            <td>{slot}</td>
                                            <td colSpan="7" className="text" style={{ fontSize: '', fontWeight: '200', color: 'DarkBlue', fontFamily: '' }}>↯</td>
                                        </tr>
                                    );

                                } else if (tasksInSlot.length === 0) {
                                    return (
                                        <tr key={index}>
                                            <td>{slot}</td>
                                            <td colSpan="7" className="text" style={{ fontSize: '', fontWeight: 'Bold', color: 'green', fontFamily: '' }}>vacant</td>
                                        </tr>
                                    );
                                }

                                return tasksInSlot.map((task, taskIndex) => (
                                    <tr key={taskIndex}>
                                        {taskIndex === 0 && <td rowSpan={tasksInSlot.length}>{slot}</td>}
                                        <td style={{ fontSize: '16px', fontWeight: '600', color: 'DarkBlue', fontFamily: '' }}>{task.name}</td>
                                        <td>{task.time}</td>
                                        <td>{task.details}</td>
                                        <td>{new Date(task.date).toLocaleDateString()}</td>
                                        <td style={{ fontWeight: '500', textAlign: 'center' }}>{task.output}</td>
                                        <td style={{ fontWeight: '500', color: '', fontFamily: '', textAlign: 'center' }}>{task.teamString || 'N/A'}</td>
                                        <td>
                                            <button className="btn btn-sm btn-danger" onClick={() => handleDeleteTask(task._id)}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </button>
                                        </td>
                                    </tr>
                                ));
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };



    return (
        <div className="container-fluid p-0">
            <div className="custom-bg-color py-3 mb-4 pl-5" style={{ marginLeft: '-15px', marginRight: '-15px', display: 'flex', alignItems: 'center' }}>
                <h2 className="text-left text-white" style={{ fontSize: '38px', fontWeight: '500', color: '', fontFamily: '' }}>Dashboard</h2>
                <div style={{ width: '6px' }}></div> {/* Add space */}
                <h2 className="text-left text-white" style={{ fontSize: '14px', fontWeight: '450', color: '', fontFamily: '' }}>Calendar with ashraful</h2>
            </div>

            <div className="global-margin">
                {/* Row for Team Creation */}
                <div className="row justify-content-end mb-0">
                    <div className="col-auto">
                        <TeamForm onTeamSubmit={handleTeamSubmit} />
                    </div>
                </div>


                {/* Row for Team Selection and Link Copy, ensuring no space between */}
                <div className="row justify-content-end">
                    <div className="col-auto me-2">
                        <select className="form-select" value={selectedTeam} onChange={handleTeamSelection}>
                            <option value="">Select a Colab</option>
                            {teams.map(team => (
                                <option key={team._id} value={team._id}>{team.name}</option>
                            ))}
                        </select>
                    </div>
                    {selectedTeam && (
                        <div className="col-auto me-2">
                            <button className="btn btn-success" onClick={openLinkInNewTab} style={{
                                backgroundColor: 'green', // Blue background color
                                color: '#ffffff', // White text color
                                fontWeight: 'bold', // Bold font weight
                                borderRadius: '5px', // Rounded corners
                                border: 'none', // No border
                                padding: '6px 12px', // Padding
                                cursor: 'pointer', // Cursor style
                                width: '150px', // Custom width
                                height: '38px', // Custom height
                            }}>Open Colab Link</button>
                        </div>

                    )}
                </div>

                {/* The rest of your component */}

                <div className="row justify-content-center mt-3">
                    <div className="col-md-8">
                        <TaskForm onTaskSubmit={handleTaskSubmit} />
                    </div>
                </div>

                {/* Row for Date Selection and Task List Display */}
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="d-flex">
                                <input
                                    type="date"
                                    className="form-control"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    style={{ width: '150px', color: 'green', marginBottom: '25px', fontSize: '', fontWeight: '510', fontFamily: '' }}
                                />
                                <button
                                    className="btn btn-secondary ml-2"
                                    onClick={fetchTasksForSelectedDate}
                                    style={{
                                        backgroundColor: 'green', // Blue background color
                                        color: '#ffffff', // White text color
                                        fontWeight: 'bold', // Bold font weight
                                        borderRadius: '5px', // Rounded corners
                                        border: 'none', // No border
                                        padding: '6px 12px', // Padding
                                        cursor: 'pointer', // Cursor style
                                        width: '120px', // Custom width
                                        height: '38px', // Custom height
                                    }}
                                >
                                    Refresh
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Task Display */}
                <div className="row mt-3">
                    <div className="col-12" style={{ marginBottom: '100px' }}>

                        {showTimeSlotStringList()} {/* Display the time slots list */}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Dashboard;

