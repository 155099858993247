import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

function TaskForm({ onTaskSubmit }) {
    const [taskName, setTaskName] = useState('');
    const [taskDate, setTaskDate] = useState('');
    const [taskDetails, setTaskDetails] = useState('');
    const [taskOutput, setTaskOutput] = useState('');
    const [taskTime, setTaskTime] = useState(''); // Start time
    const [taskEndTime, setTaskEndTime] = useState(''); // End time
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [teamstring, setTeamString] = useState('');

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await fetch('https://backendcalendarwithashraful.cloudns.ch/api/teams', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch teams');
                }
                const data = await response.json();
                setTeams(data);
            } catch (error) {
                console.error('Error fetching teams:', error);
            }
        };

        fetchTeams();
    }, []);


    const convertTo12HourFormat = (time) => {
        const [hour, minute] = time.split(':');
        const hours = parseInt(hour, 10);
        const suffix = hours >= 12 ? "PM" : "AM";
        const adjustedHour = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

        return `${adjustedHour}:${minute} ${suffix}`;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Combine the start and end times into a single string
        const formattedStartTime = convertTo12HourFormat(taskTime);
        const formattedEndTime = convertTo12HourFormat(taskEndTime);

        const task = {
            name: taskName,
            date: taskDate,
            details: taskDetails,
            output: taskOutput,
            time: `${formattedStartTime}-${formattedEndTime}`, // Combined time in 12-hour format
            team: selectedTeam,
            teamString: teamstring,
        };

        onTaskSubmit(task);

        // Reset form fields after submission
        setTaskName('');
        setTaskDate('');
        setTaskDetails('');
        setTaskOutput('');
        setTaskTime('');
        setTaskEndTime('');
        setSelectedTeam('');
        setTeamString('');
    };

    return (
        <div className="container my-4">
            <h2 className="text-center mb-4" style={{ fontSize: '22px', fontWeight: '800', color: 'green', fontFamily: '' }}>Add New Task</h2>
            <form onSubmit={handleSubmit} className="row g-3">
                <div className="col-md-6">
                    <input type="text" className="form-control" placeholder="Task Name" value={taskName} onChange={(e) => setTaskName(e.target.value)} />
                </div>
                <div className="col-md-6">
                    <input type="date" className="form-control" placeholder="Date" value={taskDate} onChange={(e) => setTaskDate(e.target.value)} />
                </div>
                <div className="col-12">
                    <input type="text" className="form-control" placeholder="Details" value={taskDetails} onChange={(e) => setTaskDetails(e.target.value)} />
                </div>
                <div className="col-12">
                    <input type="text" className="form-control" placeholder="Output" value={taskOutput} onChange={(e) => setTaskOutput(e.target.value)} />
                </div>
                <div className="col-md-6">
                    <input type="time" className="form-control" placeholder="Start Time" value={taskTime} onChange={(e) => setTaskTime(e.target.value)} />
                </div>
                <div className="col-md-6">
                    <input type="time" className="form-control" placeholder="End Time" value={taskEndTime} onChange={(e) => setTaskEndTime(e.target.value)} />
                </div>
                <div className="col-12">
                    <select className="form-control" value={selectedTeam} onChange={(e) => {
                        const selectedTeamId = e.target.value;
                        setSelectedTeam(selectedTeamId);
                        const selectedTeamObj = teams.find(team => team._id === selectedTeamId);
                        setTeamString(selectedTeamObj ? selectedTeamObj.name : '');
                    }}>
                        <option value="">Select a Colab</option>
                        {teams.map((team) => (
                            <option key={team._id} value={team._id}>{team.name}</option>
                        ))}
                    </select>

                </div>
                <div className="col-12 text-center">
                    <button type="submit" className="btn btn-success" style={{
                                    backgroundColor: 'green', // Blue background color
                                    color: '#ffffff', // White text color
                                    fontWeight: 'bold', // Bold font weight
                                    borderRadius: '5px', // Rounded corners
                                    border: 'none', // No border
                                    padding: '6px 12px', // Padding
                                    cursor: 'pointer', // Cursor style
                                    width: '120px', // Custom width
                                    height: '38px', // Custom height
                                }}>Add Task</button>
                </div>
               
            </form>
        </div>
    );
}

export default TaskForm;

